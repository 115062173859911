import React, {useState} from "react";
import {Button, Dialog, List, Result, Toast} from "antd-mobile";
import {useInterval, useWxPay} from "@utils/app_hooks";
import {useNavigate} from "react-router-dom";
import { createPayment } from "@api/index";
import Tools from "@utils/tools";
import wx from "weixin-js-sdk-ts";
import {useMount, useSetState} from "ahooks";
import {getSessionStorage} from "@utils/my_session";
import PayUtils from '@utils/pay_utils'
import {getRechargeInfo} from "@api/add_oil";
import '../oilpay/index.scss'

const RechargePay = ()=> {
  const [payStatus,setPayStatus] = useState('waiting')
  const naviagtor = useNavigate();
  const [count, setCount] = useState(60);
  let [code_status, setStatus] = useState(false);
  const urlParam = Tools.param2Obj(window.location.search);
  const [orderInfo, setOrderInfo] = useSetState<any>({
    money: 0,
    ordersn: "",
    room: {
      name: ''
    },
    emp: {
      name: ''
    },
    user: {
      mobile: ''
    }
  });
  const userWxPay = useWxPay();

  useMount( async ()=> {
    if (urlParam.hasOwnProperty('paysn')) {
      await getUserRecharge()
    }
  })

  useInterval(() => {
    if (!code_status) {
      if (count === 1) {
        setCount(60);
        setStatus(true);
        return;
      }
      setCount(count - 1);
    } else wx.closeWindow();
  }, 1000);

  const getUserRecharge = async ()=> {
    const res = await getRechargeInfo(urlParam.app_id, urlParam.paysn);
    if (res.code === 200) {
      setOrderInfo(res.data);
    }
  }

  const createPay = ()=> {
    Dialog.confirm({
      title: '确认支付',
      content: '是否确认支付该订单',
      onConfirm: ()=> makePay(),
      onCancel: ()=> Toast.show('您取消了该订单')
    })
  }

  const makePay = async ()=> {
    try {
      let obj = {
        app_id: urlParam.app_id,
        paysn: urlParam.paysn,
        source: Tools.getSystemPayEnv()
      }
      if (Tools.getSystemPayEnv() === 'wx') {
        if (!urlParam.hasOwnProperty('wx_token')) return
        obj = Object.assign(obj, {
          wx_token: urlParam.wx_token,
        })
      } else {
        obj = Object.assign(obj, {
          ali_token: getSessionStorage('ali_token')
        })
      }
      const res = await createPayment(obj);
      if (res.code === 200) {
        if (Tools.getSystemPayEnv() === 'wx') {
          await userWxPay.pay({
            ...res.data,
            paysn: urlParam.paysn
          });
        } else {
          const status = await PayUtils.aliPay(res.data.targetOrderId)
          naviagtor(`/pay_result?status=${Number(status)}&paysn=${urlParam.paysn}`)
        }
      } else {
        setPayStatus('error')
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <Result
        // @ts-ignore
        status={payStatus}
        // img={myImg('https://gw.alipayobjects.com/zos/rmsportal/HWuSTipkjJRfTWekgTUG.svg')}
        title={`支付中...(${count}s)`}
        description="请在规定时间内完成支付"
      />
      <List>
        <List.Item extra={`${orderInfo.money}元`}>充值金额</List.Item>
        <List.Item extra={orderInfo.room.name}>当前门店</List.Item>
        <List.Item extra={orderInfo.emp.name}>服务员工</List.Item>
      </List>
      <div style={{padding: '0 10px', marginTop: '10px'}} onClick={()=> createPay()}>
        <Button type={'button'} block color={'primary'}>立即支付</Button>
      </div>
    </>
  )
}

export default RechargePay;
